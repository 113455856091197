import { getPersonalisationParams } from "./selectors";
import {
  ADVERTISING_CONSENT_QUERY,
  ADVERTISING_VISITOR_QUERY,
  FEATURES_ID_COOKIE
} from "./constants";
import cookie from "js-cookie";
import {
  TARGETING_AND_ADVERTISING_COOKIES_ID,
  hasConsent
} from "@asosteam/asos-web-consent";

export const getAdvertisingVisitorQueryParams = state => {
  let params = {};

  const { CustomerGuid } = getPersonalisationParams(state);
  if (CustomerGuid) {
    params = {
      ...params,
      [ADVERTISING_CONSENT_QUERY]: true,
      [ADVERTISING_VISITOR_QUERY]: CustomerGuid
    };
  }

  if (CLIENT && !CustomerGuid) {
    const featuresId = cookie.get(FEATURES_ID_COOKIE);
    const consented = hasConsent([TARGETING_AND_ADVERTISING_COOKIES_ID]);

    if (consented) {
      params = {
        ...params,
        [ADVERTISING_VISITOR_QUERY]: featuresId
      };
    }

    params = {
      ...params,
      [ADVERTISING_CONSENT_QUERY]: consented
    };
  }

  return params;
};
