import getKeyStoreDataversion from "../../../../helpers/getKeyStoreDataversion";
import {
  SMALL_VIEWPORT,
  MEDIUM_VIEWPORT,
  LARGE_VIEWPORT
} from "../../layout/constants";
import { getViewportCategory } from "../../layout/selectors";
import {
  getPersonalisationParams,
  getAdditionalQueryParams
} from "../../search/selectors";
import { MOBILE, TABLET, DESKTOP } from "./../constants";
import getBaseUrl from "./getBaseUrl";

export { getBaseUrl };
export const getFloor = state => state.config.floor;
export const getCountry = state => state.config.country;
export const getPlatform = state => state.config.platform;

const getLayout = state => {
  const layout = getViewportCategory(state);

  if (!layout) {
    return;
  }

  switch (layout) {
    case SMALL_VIEWPORT:
      return MOBILE;
    case MEDIUM_VIEWPORT:
      return TABLET;
    case LARGE_VIEWPORT:
    default:
      return DESKTOP;
  }
};

export const getLayoutOrPlatform = state =>
  getLayout(state) || getPlatform(state);

export const getRowLength = state => {
  switch (getLayoutOrPlatform(state)) {
    case MOBILE:
      return 2;
    case TABLET:
      return 3;
    case DESKTOP:
    default:
      return 4;
  }
};

export const getChannel = state => {
  switch (getLayoutOrPlatform(state)) {
    case MOBILE:
      return "mobile-web";
    case TABLET:
    case DESKTOP:
    default:
      return "desktop-web";
  }
};

export const getCuratedCategoryChannel = state => {
  switch (getLayoutOrPlatform(state)) {
    case MOBILE:
      return "app";
    case TABLET:
    case DESKTOP:
    default:
      return "web";
  }
};

export const getLocale = state => state.config.locale.language;
export const getCurrency = state => state.config.currency;
export const getCurrencyCode = state => getCurrency(state).currency;
export const getStoreCode = state => getCountry(state).storeCode;
export const getCountryCode = state => getCountry(state).countryCode;
export const getSizeSchema = state => state.config.sizeSchema;
export const getSearchApiDefaults = state => {
  const keyStoreDataversion = getKeyStoreDataversion(state);
  const personalisationParams = getPersonalisationParams(state);
  const additionalQueryParams = getAdditionalQueryParams(state);

  const defaults = {
    store: getStoreCode(state),
    lang: getLocale(state),
    currency: getCurrencyCode(state),
    rowlength: getRowLength(state),
    channel: getChannel(state),
    country: getCountryCode(state)
  };

  return {
    ...additionalQueryParams,
    ...defaults,
    ...personalisationParams,
    keyStoreDataversion
  };
};

export const getStoreUrl = state => state.config.storeUrl;

export const isTestEndpoint = url => url.includes("asosservices");

export const buildUrl = ({ storeUrl, storeCode }, url) => {
  if (!url) return storeUrl;

  const storePrefixRegex = new RegExp(`^/?(${storeCode}/)?`, "i");

  return `${storeUrl}/${url.replace(storePrefixRegex, "")}`;
};

export const getNorthAmericanRegion = state => {
  const storeCode = getStoreCode(state);
  if (storeCode !== "US") {
    return null;
  }

  const { countryCode, regionCode } = state.config.geolocation;

  if (countryCode === "US") {
    return regionCode;
  }

  if (["CA", "MX"].includes(countryCode)) {
    return countryCode;
  }

  return null;
};
