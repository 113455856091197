import commonConfig from "../../../config/common";
import { getCountryCode } from "../config/selectors";
import { ADVERTISING_PARTNER_QUERY } from "./constants";
import { getAdvertisingVisitorQueryParams } from "./getAdvertisingVisitorQueryParams";

export const getAdvertisingQueryParams = state => {
  const { criteoPartnerIds } = commonConfig;
  const countryCode = getCountryCode(state);
  const partnerIdForCountry = criteoPartnerIds[countryCode];
  let params = {};

  if (partnerIdForCountry) {
    params = {
      [ADVERTISING_PARTNER_QUERY]: partnerIdForCountry,
      ...getAdvertisingVisitorQueryParams(state)
    };
  }

  return params;
};
